/**
 * This file is part of VILLASweb.
 *
 * VILLASweb is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * VILLASweb is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with VILLASweb. If not, see <http://www.gnu.org/licenses/>.
 ******************************************************************************/

import React from "react";
import { DragSource } from "react-dnd";
import classNames from "classnames";
import Icon from "../../../common/icon";

// Drag source specification
const toolboxItemSource = {
  beginDrag(props) {
    return {
      name: props.name,
    };
  },
};

// The collect function gathers props for the component related to dragging
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

// The functional component for ToolboxItem
const ToolboxItem = ({
  connectDragSource,
  isDragging,
  disabled,
  name,
  icon,
}) => {
  const itemClass = classNames({
    "toolbox-item": true,
    "toolbox-item-dragging": isDragging,
    "toolbox-item-disabled": disabled,
  });

  const content = (
    <span className="btn " style={{ marginTop: "5px" }}>
      {icon && <Icon style={{ marginRight: "5px" }} icon={icon} />}
      {name}
    </span>
  );

  return disabled ? (
    <div className={itemClass}>{content}</div>
  ) : (
    connectDragSource(<div className={itemClass}>{content}</div>, {
      dropEffect: "copy",
    })
  );
};

ToolboxItem.defaultProps = {
  disabled: false,
};

export default DragSource("widget", toolboxItemSource, collect)(ToolboxItem);
