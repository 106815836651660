/**
 * This file is part of VILLASweb.
 *
 * VILLASweb is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * VILLASweb is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with VILLASweb. If not, see <http://www.gnu.org/licenses/>.
 ******************************************************************************/

export const dashboardEndpoints = (builder) => ({
    getDashboards: builder.query({
      query: (scenarioID) => `dashboards?scenarioID=${scenarioID}`,
    }),
    addDashboard: builder.mutation({
      query: (dashboard) => ({
        url: 'dashboards',
        method: 'POST',
        body: dashboard,
      }),
    }),
    deleteDashboard: builder.mutation({
      query: (dashboardID) => ({
        url: `dashboards/${dashboardID}`,
        method: 'DELETE',
      }),
    }),
    updateDashboard: builder.mutation({
      query: ({ dashboardID, dashboard }) => ({
        url: `dashboards/${dashboardID}`,
        method: 'PUT',
        body: { dashboard },
      }),
    }),
    getDashboard: builder.query({
      query: (dashboardID) => `/dashboards/${dashboardID}`,
    }),
});
